define("discourse/plugins/pg-meta-index-mapping/discourse/initializers/pg-meta-index-mapping", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function mapMetaData(api, siteSettings) {
    api.decorateWidget("post-avatar:after", dec => {
      const attrs = dec.attrs;
      const _post = attrs.post_data ? attrs.post_data : [];
      if (_post.length > 0 && _post[0].count < 4) {
        if (document.querySelectorAll('meta[content="noindex"]').length < 1) {
          var meta = document.createElement("meta");
          meta.name = "robots";
          meta.content = "noindex";
          document.getElementsByTagName("head")[0].appendChild(meta);
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "pg-meta-index-mapping",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.pg_meta_index_mapping_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", api => {
          mapMetaData(api, siteSettings);
          api.includePostAttributes("post_data");
        });
      }
    }
  };
});